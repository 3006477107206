<template>
    <section class="post">
        <div class="container">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-md-12 text-center">
                        <template
                            v-if="
                                simpleData &&
                                    simpleData.result &&
                                    (simpleData.result.e === 'SUCCESS' ||
                                        simpleData.result.is_success)
                            "
                        >
                            <h1 class="title mb-4">Sikeres rendelés</h1>
                            <p>
                                Sikeresen leadta rendelését! <br />
                                A megrendelésével kapcsolatos részleteket, a
                                megrendelés során megadot e-mail címére fogjuk
                                elküldeni.
                            </p>
                            <p v-html="simpleData.result_formatted"></p>
                        </template>
                        <template v-else>
                            <h1 class="title mb-4">Sikertelen rendelés</h1>
                            <h2
                                class="title"
                                v-if="
                                    simpleData &&
                                        simpleData.result &&
                                        simpleData.result.e === 'TIMEOUT'
                                "
                            >
                                {{
                                    $t(
                                        "Túllépte a tranzakció maximális idő limitét."
                                    )
                                }}
                            </h2>
                            <h2
                                class="title"
                                v-if="
                                    simpleData &&
                                        simpleData.result &&
                                        simpleData.result.e === 'CANCEL'
                                "
                            >
                                {{ $t("Megszakított fizetés.") }}
                            </h2>
                            <p
                                class="text-danger"
                                v-if="
                                    simpleData.result &&
                                        simpleData.result.e !== 'CANCEL'
                                "
                                v-html="simpleData.result_formatted"
                            ></p>
                        </template>
                        <router-link
                            class="d-block mx-auto continue-shop-btn transition mb-2 mt-2"
                            :to="'/'"
                            >Vissza a főoldalra</router-link
                        >
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
    name: "CheckoutSuccess",
    computed: {
        url: function() {
            return process.env.VUE_APP_API_URL;
        }
    },
    data() {
        return {
            simpleData: {
                result: {
                    e: null
                }
            }
        };
    },
    beforeMount() {
        const self = this;

        if (Object.keys(self.$route.query).length < 1) {
            this.simpleData.result.e = "SUCCESS";
            self.$eventHub.$emit("updateCart", true);
        } else {
            ApiService.post(`${self.url}/backref`, self.$route.query)
                .then(response => {
                    self.simpleData = response.data;
                    localStorage.setItem("cart_uuid", response.data.cart.uuid);
                })
                .catch(err => {
                    console.log(err);
                    // this.$router.push({ name: 'NotFound' })
                })
                .finally(() => {
                    self.$eventHub.$emit("updateCart", true);
                });
        }
    }
};
</script>

<style scoped></style>
