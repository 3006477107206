<template>
    <div class="content">
        <BreadCrumbs
            :elements="[
                { link: 'Cart', name: 'Kosár' },
                { link: 'CheckoutBilling', name: 'Számlázási adatok' },
                { link: 'CheckoutShipping', name: 'Szállítási mód' }
            ]"
        />
        <section class="listSection listing">
            <div class="container">
                <div id="pages">
                    <div class="headline">
                        <h1>Szállítási adatok</h1>
                        <hr />
                    </div>
                    <CheckoutBreadcrumbs :step="3" />
                    <div class="row">
                        <div class="col-12">
                            <form v-on:submit.prevent="nextStep">
                                <div class="my-4 radio-corrector">
                                    <label
                                        class="d-block mb-3"
                                        v-for="(item, index) in model"
                                        v-bind:key="'shipping-' + index"
                                    >
                                        <input
                                            required
                                            type="radio"
                                            name="shipping"
                                            :value="item.id"
                                            v-model="form.shipping"
                                            v-bind:checked="index === 0"
                                        />
                                        {{ item.name }}
                                        <template
                                            v-if="parseInt(item.price) === 0"
                                        >
                                            Ingyenes
                                        </template>
                                        <template
                                            v-if="
                                                item.discount_from !== null &&
                                                    item.discount_to !== null &&
                                                    item.discount_from !==
                                                        '0000-00-00 00:00:00' &&
                                                    item.discount_to !==
                                                        '0000-00-00 00:00:00' &&
                                                    item.discount_from <=
                                                        dateNow &&
                                                    item.discount_to >= dateNow
                                            "
                                        >
                                            ({{ item.discount_price }} Ft / db)
                                        </template>
                                        <template v-else>
                                            <template
                                                v-if="
                                                    parseInt(item.price_1) >
                                                        0 ||
                                                        parseInt(item.price) > 0
                                                "
                                            >
                                                (
                                                <template
                                                    v-if="
                                                        parseInt(item.price_1) >
                                                            0
                                                    "
                                                >
                                                    1 DB VÁSÁRLÁSA ESETÉN:
                                                    {{ item.price_1_formatted }}
                                                    / DB ;
                                                </template>
                                                <template
                                                    v-if="
                                                        parseInt(item.price) > 0
                                                    "
                                                >
                                                    2 VAGY TÖBB GUMIABRONCS
                                                    VÁSÁRLÁSAKOR
                                                    {{ item.price_formatted }} /
                                                    DB
                                                </template>
                                                )
                                            </template>
                                            <!--<template v-if="cartItemsCount < 2">
                                                ({{ item.price_1_formatted }}
                                                / db)
                                            </template>
                                            <template v-else>
                                                ({{ item.price_formatted }} /
                                                db)
                                            </template>-->
                                        </template>
                                        <br />
                                        <small v-if="item.description">
                                            ( {{ item.description }} )
                                        </small>
                                    </label>
                                    <div
                                        v-if="
                                            form.shipping && form.shipping === 4
                                        "
                                        class="alert alert-danger"
                                        role="alert"
                                        style="margin-top: 10px;"
                                    >
                                        <strong
                                            >Kérjük vegye figyelembe, hogy ennél
                                            a szállítási módnál csak
                                            bankkártyával és előreutalással tud
                                            fizetni!</strong
                                        >
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-8">
                                        <router-link
                                            :to="'/'"
                                            class="d-block continue-shop-btn transition"
                                            >Vásárlás folytatása
                                        </router-link>
                                    </div>
                                    <div class="col-md-4 text-end">
                                        <button
                                            type="submit"
                                            class="btn btn-primary w-100 mb-2"
                                        >
                                            Tovább
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import BreadCrumbs from "@/components/BreadCrumbs";
import CheckoutBreadcrumbs from "@/components/CheckoutBreadcrumbs";

export default {
    name: "CheckoutShipping",
    components: { CheckoutBreadcrumbs, BreadCrumbs },
    data() {
        return {
            model: {},
            form: {
                shipping: null
            },
            cart: {}
        };
    },
    beforeMount() {
        if (!$cookies.get("billing")) {
            this.$router.push({ name: "Cart" });
        }
        this.getShipping();

        const cartUuid = $cookies.get("cart_uuid");
        ApiService.query(`${this.url}/cart?uuid=${cartUuid}`).then(response => {
            $cookies.set("cart_uuid", response.data.data.uuid, 864000);
            this.cart = response.data.data;
        });
    },
    computed: {
        url: function() {
            return process.env.VUE_APP_API_URL;
        },
        dateNow: function() {
            let yourDate = new Date();
            return yourDate.toISOString().split("T")[0];
        },
        cartItemsCount() {
            let db = 0;
            for (const it in this.cart.items) {
                db += this.cart.items[it].quantity;
            }
            return db;
        }
    },
    methods: {
        getShipping() {
            ApiService.get(`${this.url}/shipping/get`)
                .then(response => {
                    this.model = response.data.data;
                    this.form.shipping = this.model[0].id;
                })
                .finally(() => {
                    if ($cookies.get("shipping")) {
                        this.form.shipping = $cookies.get("shipping");
                    }
                });
        },
        nextStep() {
            const cartUuid = $cookies.get("cart_uuid");
            ApiService.post(
                `${this.url}/cart/shipping?uuid=${cartUuid}&shipping=${this.form.shipping}`
            ).then(() => {
                $cookies.set("shipping", this.form.shipping, 86400);
                for (let i = 0; i < this.model.length; i++) {
                    if (this.model[i].id === this.form.shipping) {
                        $cookies.set(
                            "shipping_name",
                            this.model[i].name,
                            86400
                        );
                    }
                }
                this.$router.push({ name: "CheckoutPayment" });
            });
        }
    }
};
</script>

<style scoped></style>
