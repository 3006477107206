import { render, staticRenderFns } from "./CheckoutShipping.vue?vue&type=template&id=0ece5616&scoped=true&"
import script from "./CheckoutShipping.vue?vue&type=script&lang=js&"
export * from "./CheckoutShipping.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ece5616",
  null
  
)

export default component.exports